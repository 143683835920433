import React from 'react';
import './blog.css';
import LatestNewsSection from '../components/LatestNewsSection/LatestNewsSection';
import BlogHeroSection from '../components/BlogHeroSection/BlogHeroSection';
import BlogCategoriesSection from '../components/BlogCategoriesSection/BlogCategoriesSection';
import BlogPostsSection from '../components/BlogPostsSection/BlogPostsSection';
import BlogFAQSection from '../components/BlogFAQSection/BlogFAQSection';
import Seo from '../components/Seo';
import Layout from '../components/layout';
import CallToActionSection from '../components/CallToActionSection/CallToActionSection';
import Breadcrumbs from '../components/Breadcrumbs/breadcrumbs';

const Content = ({ data }) => {
  const crumbs = [
    { path: '/', label: 'Página Inicial' },
    { path: '/blog', label: 'Blog' }
  ];

  return (
    <Layout>
      <Breadcrumbs crumbs={crumbs} />
      <div className="about-container">
        <Seo
          title="Blog - Prontuário Fácil: Dicas e informações sobre gestão de clínicas"
          description="No blog do Prontuário Fácil você encontra dicas, informações e novidades sobre gestão de clínicas, prontuário eletrônico, atendimento médico e muito mais."
          keywords="prontuário eletrônico, software médico, gestão de clínicas, blog, saúde, dicas, notícias"
        />
        <main className="content">
          <BlogHeroSection />
          <BlogCategoriesSection />
          <BlogPostsSection />
          <LatestNewsSection />
          <BlogFAQSection />
          <CallToActionSection />
        </main>
      </div>
    </Layout>
  );
};

export default Content;
