import React, { useState } from 'react';
import './BlogFAQSection.css';
import { FiPlus, FiMinus } from 'react-icons/fi';

const BlogFAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'O que é telemedicina e como ela pode me beneficiar?',
      answer:
        'A telemedicina permite consultas médicas por meio de videoconferência, reduzindo a necessidade de deslocamentos e proporcionando maior comodidade.',
    },
    {
      question: 'Como posso acessar os serviços de telemedicina?',
      answer:
        'Você pode acessar os serviços de telemedicina através do nosso portal online, agendando uma consulta com qualquer um de nossos especialistas.',
    },
    {
      question: 'Os serviços de telemedicina são seguros?',
      answer:
        'Sim, garantimos a segurança e a privacidade de todas as consultas através de criptografia e conformidade com normas de proteção de dados.',
    },
    {
      question: 'Quais são os benefícios da telemedicina?',
      answer:
        'A telemedicina oferece diversos benefícios, como maior comodidade, redução de custos, acesso a especialistas de diferentes regiões e maior flexibilidade no agendamento de consultas.',
    },
    {
      question: 'Como a telemedicina funciona na prática?',
      answer:
        'A telemedicina funciona por meio de videoconferência, permitindo que o paciente converse com o médico online, visualize seus sintomas e receba um diagnóstico e tratamento.',
    },
    {
      question: 'Quais tipos de consultas podem ser realizadas por telemedicina?',
      answer:
        'A telemedicina pode ser utilizada para uma ampla gama de consultas médicas, como consultas de rotina, acompanhamento de doenças crônicas, consultas com especialistas, entre outras.',
    },
  ];

  return (
    <div className="blog-faq" aria-labelledby="blog-faq-title">
      <h2 id="blog-faq-title">Perguntas Frequentes</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleFAQ(index)}
            onKeyPress={(e) => (e.key === 'Enter' || e.key === ' ') && toggleFAQ(index)}
            tabIndex={0}
            role="button"
            aria-expanded={activeIndex === index}
            aria-controls={`faq-answer-${index}`}
          >
            <div className="faq-question">
              <h3 id={`faq-question-${index}`}>{faq.question}</h3>
              <span>{activeIndex === index ? <FiMinus /> : <FiPlus />}</span>
            </div>
            {activeIndex === index && (
              <div className="faq-answer" id={`faq-answer-${index}`} role="region" aria-labelledby={`faq-question-${index}`}>
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogFAQSection;
