import React from 'react';
import './BlogHeroSection.css';

const BlogHeroSection = () => {
    return (
        <section className="bhs-blog-hero" aria-labelledby="blog-hero-title" aria-describedby="blog-hero-description">
            <h1 id="blog-hero-title">Bem-vindo ao Nosso Blog</h1>
            <p id="blog-hero-description">Explore insights e atualizações do mundo da saúde.</p>
        </section>
    );
};

export default BlogHeroSection;
