import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import './BlogPostsSection.css';

const BlogPostsSection = () => {
    const data = useStaticQuery(graphql`
        query {
            allSanityPost(sort: { publishedAt: DESC }) {
                edges {
                    node {
                        title
                        slug {
                            current
                        }
                        excerpt
                        publishedAt(formatString: "MMMM DD, YYYY")
                    }
                }
            }
        }
    `);

    const posts = data.allSanityPost.edges;
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 8;
    
    // Calculate the posts for the current page
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
    
    // Calculate the total number of pages
    const totalPages = Math.ceil(posts.length / postsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <section className="bps-blog-posts-section" aria-label="Seção de Posts do Blog">
            <div className="bps-blog-posts">
                {currentPosts.map(({ node }, index) => (
                    <Link
                        to={`/blog/${node.slug.current}`}
                        key={index}
                        className="bps-post"
                        aria-label={`Leia o post: ${node.title}`}
                    >
                        <h2 className="bps-post-title">{node.title}</h2>
                        <p className="bps-post-excerpt">{node.excerpt}</p>
                        <p className="bps-post-date">{node.publishedAt}</p>
                    </Link>
                ))}
            </div>
            <nav className="bps-pagination" aria-label="Paginação de Posts do Blog">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={index + 1 === currentPage ? 'active' : ''}
                        aria-current={index + 1 === currentPage ? 'page' : undefined}
                        aria-label={`Ir para a página ${index + 1}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </nav>
        </section>
    );
};

export default BlogPostsSection;
