import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import './BlogCategoriesSection.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BlogCategoriesSection = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityCategory {
        edges {
          node {
            title
            id
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const categories = data.allSanityCategory.edges.map(edge => edge.node);
  const [isCarousel, setIsCarousel] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsCarousel(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Verifica o tamanho da janela no carregamento

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="bcs-blog-categories" aria-label="Seção de Categorias do Blog">
      {isCarousel ? (
        <Slider {...settings} aria-label="Carrossel de Categorias do Blog">
          {categories.map(category => (
            <div key={category.id} className="bcs-category-slide">
              <Link
                to={`/blog/categoria/${category.slug.current}`}
                className="bcs-category-link"
                aria-label={`Categoria ${category.title}`}
              >
                {category.title}
              </Link>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="bcs-category-grid">
          {categories.map(category => (
            <Link
              key={category.id}
              to={`/blog/categoria/${category.slug.current}`}
              className="bcs-category-link"
              aria-label={`Categoria ${category.title}`}
            >
              {category.title}
            </Link>
          ))}
        </div>
      )}
    </section>
  );
};

export default BlogCategoriesSection;
